import { Trans } from "@lingui/macro";
import Error from "./_error";

const NotFound = (): JSX.Element => {
  const title = <Trans>Oops! Page not found</Trans>;
  const text = (
    <Trans>
      {`It looks like the page you're looking for doesn't exist. Don't worry, we have plenty of options to get you back on track:`}
    </Trans>
  );

  return <Error href={"/"} title={title} text={text} />;
};

export default NotFound;
